import tw, { styled } from 'twin.macro';
import { BREAKPOINTS } from '../../constants';

export const Wrapper = styled.article(() => {
  const out = [
    tw`bg-white text-center static py-0 md:bg-opacity-80 lg:relative flex flex-col flex-1`,
    {
      left: 0,
      right: 0,
      top: -165,
      marginBottom: -165,
      zIndex: 0,
      [`@media screen and (max-width: ${BREAKPOINTS.lg}px)`]: {
        top: 'auto',
        marginBottom: 'initial',
      },
    },
  ];
  return out;
});
export const ApplicationWrapper = styled.main(() => tw`overflow-hidden bg-gray-light flex flex-col flex-1`);

export const FancyWrapper = styled.section(() => {
  const out = [
    tw`flex bg-gray-light flex-1`,
    {
      marginTop: -32,
      [`@media screen and (max-width: ${BREAKPOINTS.lg}px)`]: {
        marginTop: -92,
      },
      [`@media screen and (max-width: ${BREAKPOINTS.xs}px)`]: {
        marginTop: 0,
      },
    },
  ];
  return out;
});

export const FancyBoxWrapper = styled.div(() => {
  const out = [
    tw`flex flex-col justify-start w-full lg:justify-center bg-white max-w-7xl mx-auto relative z-10 py-8 px-6 md:px-16 lg:mx-auto`,
    {
      marginTop: '-3.2rem',
      boxShadow: '0px 0px 20px 0px rgba(0,0,0,1)',
      [`@media screen and (max-width: ${BREAKPOINTS.md}px)`]: {
        boxShadow: 'none',
      },
      [`@media screen and (max-width: ${BREAKPOINTS.xs}px)`]: {
         marginTop: '-1.2rem',
      },
    },
  ];
  return out;
});
